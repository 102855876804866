import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  styled
} from '@mui/material';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 12,
    padding: theme.spacing(2),
    maxWidth: 500
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: 20,
  fontWeight: 600,
  textAlign: 'center',
  paddingBottom: 8
});

const StyledDialogContent = styled(DialogContent)({
  paddingTop: '16px !important',  // Override Material-UI default padding
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '& .MuiCheckbox-root': {
    color: '#6366F1',  // Match your app's primary color
    '&.Mui-checked': {
      color: '#6366F1'
    }
  },
  '& .MuiTypography-root': {
    fontSize: '0.95rem',
    fontWeight: 500
  }
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  borderRadius: 8,
  padding: '8px 20px',
  fontWeight: 600,
  fontSize: '0.95rem'
});

const GA4RelatedDataDialog = ({ 
  open, 
  onClose,
  onConfirm,
  selectedPlatform,
  selectedProperty,
  dateRange 
}) => {
  // Determine which checkboxes to show based on the selected platform
  const showGA4Option = selectedPlatform?.id === 'ga4_user' || selectedPlatform?.id === 'ga4_platform';
  const showGA4UserOption = selectedPlatform?.id === 'ga4' || selectedPlatform?.id === 'ga4_platform';
  const showGA4PlatformOption = selectedPlatform?.id === 'ga4' || selectedPlatform?.id === 'ga4_user';

  const [includeGA4, setIncludeGA4] = useState(false);
  const [includeUserData, setIncludeUserData] = useState(false);
  const [includePlatformData, setIncludePlatformData] = useState(false);

  const handleConfirm = () => {
    onConfirm({
      includeGA4,
      includeUserData,
      includePlatformData
    });
    onClose();
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      fullWidth
    >
      <StyledDialogTitle>
        Additional GA4 Data Options
      </StyledDialogTitle>
      
      <StyledDialogContent>
        <Typography 
          variant="subtitle1" 
          color="text.secondary" 
          align="center"
          sx={{ mb: 3, fontSize: '0.9rem' }}
        >
          Would you like to include additional GA4 data sources? This will help provide more comprehensive analytics.
        </Typography>
        
        <FormGroup sx={{ px: 2 }}>
          {showGA4Option && (
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={includeGA4}
                  onChange={(e) => setIncludeGA4(e.target.checked)}
                />
              }
              label="Include GA4 Analytics Data"
            />
          )}
          
          {showGA4UserOption && (
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={includeUserData}
                  onChange={(e) => setIncludeUserData(e.target.checked)}
                />
              }
              label="Include GA4 User Data"
            />
          )}
          
          {showGA4PlatformOption && (
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={includePlatformData}
                  onChange={(e) => setIncludePlatformData(e.target.checked)}
                />
              }
              label="Include GA4 Platform Data"
            />
          )}
        </FormGroup>
      </StyledDialogContent>
      
      <DialogActions sx={{ padding: 3, justifyContent: 'center', gap: 1 }}>
        <StyledButton 
          onClick={onClose}
          variant="outlined"
          sx={{ minWidth: 100 }}
        >
          Cancel
        </StyledButton>
        <StyledButton 
          onClick={handleConfirm} 
          variant="contained" 
          sx={{ 
            backgroundColor: '#6366F1',
            minWidth: 130,
            '&:hover': {
              backgroundColor: '#4F46E5'
            }
          }}
        >
          Add to Queue
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default GA4RelatedDataDialog;