import React, { useState, useEffect } from 'react';
import { useAuth, useOrganization } from "@clerk/clerk-react";
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  CircularProgress,
  TextField  // Add TextField import
} from '@mui/material';
import { Trash2, AlertTriangle } from 'lucide-react';
import TaskHistory from './TaskHistory'; 

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Progress Indicator Component
const ProgressIndicator = ({ value, total }) => (
  <Box sx={{ width: '100%', bgcolor: '#E2E8F0', borderRadius: 1, height: 8 }}>
    <Box
      sx={{
        width: `${(value / total) * 100}%`,
        bgcolor: value >= total ? '#EF4444' : '#6366F1',
        height: '100%',
        borderRadius: 1,
        transition: 'width 0.3s ease'
      }}
    />
  </Box>
);

const DatabaseManagementPanel = ({ onDatabasesChange }) => {
  const [clients, setClients] = useState([]);
  const [subscriptionLimits, setSubscriptionLimits] = useState(null);
  const [limitsLoading, setLimitsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, database: null });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { getToken } = useAuth();
  const { organization } = useOrganization();
  const [newClientName, setNewClientName] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('databases');

  useEffect(() => {
    fetchDatabaseLimits();
    fetchClients();
  }, [organization?.id]);

  const fetchDatabaseLimits = async () => {
    try {
      if (!organization?.id) return;
      
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/database-limits`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-ID': organization.id
        }
      });
      setSubscriptionLimits(response.data);
    } catch (err) {
      console.error('Error fetching database limits:', err);
    } finally {
      setLimitsLoading(false);
    }
  };

  const fetchClients = async () => {
    try {
      if (!organization?.id) return;
      
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/clients`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-ID': organization.id
        }
      });

      // Ensure we properly extract the database name
      const processedClients = response.data.map(client => {
        const dbName = typeof client === 'string' ? client : 
                      client.database_name || 
                      client.name || 
                      client.value;
        return {
          name: dbName,
          database_name: dbName,  // Keep both for compatibility
          created_at: client.created_at || new Date().toISOString(),
          is_owner: client.is_owner || true
        };
      });
      
      setClients(processedClients);
      onDatabasesChange?.(processedClients);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setError('Failed to fetch clients');
    }
  };


  const handleDeleteClick = (database) => {
    setDeleteDialog({ open: true, database });
  };

  const handleDeleteClose = () => {
    setDeleteDialog({ open: false, database: null });
  };

  const handleDeleteConfirm = async () => {
    try {
      setDeleteLoading(true);
      const token = await getToken();
      
      const response = await axios.delete(
        `${BACKEND_URL}/api/delete_database/${deleteDialog.database}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Organization-ID': organization?.id
          }
        }
      );
  
      if (response.data.success || response.data.message === "Database deleted successfully") {
        // Remove from local state immediately
        setClients(prevClients => 
          prevClients.filter(client => 
            client.name !== deleteDialog.database && 
            client.database_name !== deleteDialog.database
          )
        );
        
        // Notify parent component
        onDatabasesChange?.(clients.filter(client => 
          client.name !== deleteDialog.database && 
          client.database_name !== deleteDialog.database
        ));
        
        // Re-fetch the updated list
        await fetchClients();
      } else {
        throw new Error(response.data.error || 'Failed to delete database');
      }
      
      handleDeleteClose();
    } catch (error) {
      console.error('Error deleting database:', error);
      setError(error.response?.data?.error || 'Failed to delete database');
    } finally {
      setDeleteLoading(false);
    }
  };

// DatabaseManagementPanel.jsx
  const handleCreateNewClient = async () => {
    if (!newClientName.trim()) {
      setError('Client name is required');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const token = await getToken();
      
      await axios.post(`${BACKEND_URL}/api/create_database`, {
        name: newClientName.trim().toLowerCase(),  // Changed from db_name to name
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Organization-ID': organization?.id  // Added organization ID
        }
      });

      setNewClientName('');
      await fetchClients();
    } catch (err) {
      console.error('Error creating client:', err);
      setError(err.response?.data?.error || 'Failed to create client');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* Tab Navigation */}
      <Box sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant={activeTab === 'databases' ? 'contained' : 'text'}
            onClick={() => setActiveTab('databases')}
            sx={{
              backgroundColor: activeTab === 'databases' ? '#6366F1' : 'transparent',
              color: activeTab === 'databases' ? 'white' : '#6366F1',
              '&:hover': {
                backgroundColor: activeTab === 'databases' ? '#4F46E5' : 'transparent'
              }
            }}
          >
            Databases
          </Button>
          <Button
            variant={activeTab === 'history' ? 'contained' : 'text'}
            onClick={() => setActiveTab('history')}
            sx={{
              backgroundColor: activeTab === 'history' ? '#6366F1' : 'transparent',
              color: activeTab === 'history' ? 'white' : '#6366F1',
              '&:hover': {
                backgroundColor: activeTab === 'history' ? '#4F46E5' : 'transparent'
              }
            }}
          >
            Task History
          </Button>
        </Box>
      </Box>

      {/* Database Management Content */}
      {activeTab === 'databases' && (
        <>
          {/* Database Usage Summary */}
          {subscriptionLimits && !limitsLoading && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                Database Usage ({clients.length}/{subscriptionLimits.max_databases})
              </Typography>
              <ProgressIndicator 
                value={clients.length} 
                total={subscriptionLimits.max_databases} 
              />
              {clients.length >= subscriptionLimits.max_databases && (
                <Alert 
                  severity="warning" 
                  icon={<AlertTriangle className="h-4 w-4" />}
                  sx={{ mt: 2 }}
                >
                  You've reached your database limit for {subscriptionLimits.tier_name} tier. 
                  Please upgrade your plan to create more databases or delete existing ones.
                </Alert>
              )}
            </Box>
          )}

          {/* Database List */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" fontWeight={600} gutterBottom>
              Your Databases
            </Typography>
            {clients.map((client, index) => (
              <Box
                key={client.name || index}
                sx={{
                  p: 2,
                  my: 1,
                  border: '1px solid #E2E8F0',
                  borderRadius: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  transition: 'background-color 0.2s',
                  '&:hover': {
                    backgroundColor: '#F8FAFC'
                  }
                }}
              >
                <Box>
                  <Typography variant="subtitle1">{client.name}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Created: {new Date(client.created_at).toLocaleDateString()}
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => handleDeleteClick(client.name)}
                  size="small"
                  sx={{
                    color: 'gray',
                    '&:hover': {
                      color: '#EF4444'
                    }
                  }}
                >
                  <Trash2 size={18} />
                </IconButton>
              </Box>
            ))}
          </Box>

          {/* Create New Client Section */}
          {(!subscriptionLimits || clients.length < subscriptionLimits.max_databases) && (
            <Box sx={{ mb: 4, p: 2, border: '1px solid #E2E8F0', borderRadius: 1 }}>
              <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                Create New Client
              </Typography>
              <Box display="flex" gap={2} mt={2}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Enter client name"
                  value={newClientName}
                  onChange={(e) => setNewClientName(e.target.value)}
                  error={!!error}
                  helperText={error}
                  disabled={loading}
                />
                <Button
                  variant="contained"
                  onClick={handleCreateNewClient}
                  disabled={loading || !newClientName.trim()}
                  sx={{ 
                    minWidth: '120px',
                    backgroundColor: '#6366F1',
                    '&:hover': { backgroundColor: '#4F46E5' }
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Create'}
                </Button>
              </Box>
            </Box>
          )}

          {/* Delete Confirmation Dialog */}
          {/* Your existing delete dialog */}
        </>
      )}

      {/* Task History Content */}
      {activeTab === 'history' && (
        <TaskHistory />
      )}
    </Box>
  );
};

export default DatabaseManagementPanel;

