import React, { useState, useEffect } from 'react';
import { useAuth, useOrganization } from "@clerk/clerk-react";
import axios from 'axios';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  TextField
} from '@mui/material';
import { 
  AlertTriangle,
  CheckCircle, 
  Clock,
  XCircle,
  RefreshCw,
  Search
} from 'lucide-react';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const statusIcons = {
  'Completed': <CheckCircle className="h-4 w-4 text-green-500" />,
  'Failed': <XCircle className="h-4 w-4 text-red-500" />,
  'In Progress': <Clock className="h-4 w-4 text-blue-500" />,
  'Queued': <RefreshCw className="h-4 w-4 text-gray-500" />
};

const TaskHistory = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getToken } = useAuth();
  const { organization } = useOrganization();
  
  // Filter states
  const [platformFilter, setPlatformFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [daysFilter, setDaysFilter] = useState(30);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchTasks = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/task_history`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          platform: platformFilter || undefined,
          status: statusFilter || undefined,
          days: daysFilter
        }
      });
  
      setTasks(response.data.tasks);
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setError(err.response?.data?.error || 'Failed to fetch task history');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchTasks();
  }, [platformFilter, statusFilter, daysFilter]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed': return 'bg-green-100 text-green-800';
      case 'Failed': return 'bg-red-100 text-red-800';
      case 'In Progress': return 'bg-blue-100 text-blue-800';
      case 'Queued': return 'bg-gray-100 text-gray-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const formatDuration = (seconds) => {
    if (!seconds) return 'N/A';
    if (seconds < 60) return `${seconds}s`;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const filteredTasks = tasks.filter(task => {
    const searchLower = searchTerm.toLowerCase();
    return (
      task.platform.toLowerCase().includes(searchLower) ||
      task.property_id.toLowerCase().includes(searchLower) ||
      task.database_name.toLowerCase().includes(searchLower)
    );
  });

  return (
    <Box>
      <Typography variant="h6" fontWeight="600" gutterBottom>
        Task History
      </Typography>

      {/* Filters */}
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          size="small"
          placeholder="Search tasks..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <Search className="h-4 w-4 text-gray-400 mr-2" />
          }}
          sx={{ flex: 1 }}
        />
        
        <Select
          size="small"
          value={platformFilter}
          onChange={(e) => setPlatformFilter(e.target.value)}
          displayEmpty
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="">All Platforms</MenuItem>
          <MenuItem value="ga4">GA4</MenuItem>
          <MenuItem value="ga4_platform">GA4 Platform</MenuItem>
          <MenuItem value="ga4_user">GA4 User</MenuItem>
          <MenuItem value="gsc">Search Console</MenuItem>
          <MenuItem value="webcontent">Web Content</MenuItem>
          <MenuItem value="woocommerce">WooCommerce</MenuItem>
        </Select>

        <Select
          size="small"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          displayEmpty
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="">All Status</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
          <MenuItem value="Failed">Failed</MenuItem>
          <MenuItem value="In Progress">In Progress</MenuItem>
          <MenuItem value="Queued">Queued</MenuItem>
        </Select>

        <Select
          size="small"
          value={daysFilter}
          onChange={(e) => setDaysFilter(e.target.value)}
          sx={{ minWidth: 120 }}
        >
          <MenuItem value={7}>Last 7 days</MenuItem>
          <MenuItem value={30}>Last 30 days</MenuItem>
          <MenuItem value={90}>Last 90 days</MenuItem>
        </Select>
      </Box>

      {/* Error Alert */}
      {error && (
        <Alert 
          severity="error" 
          icon={<AlertTriangle className="h-4 w-4" />}
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}

      {/* Task Table */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Platform</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Database</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Rows</TableCell>
              <TableCell>Error</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center" sx={{ py: 3 }}>
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            ) : filteredTasks.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center" sx={{ py: 3 }}>
                  <Typography color="textSecondary">
                    No tasks found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredTasks.map((task) => (
                <TableRow key={task.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {statusIcons[task.status]}
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(task.status)}`}>
                        {task.status}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell>{task.platform}</TableCell>
                  <TableCell>{task.property_id}</TableCell>
                  <TableCell>{task.database_name}</TableCell>
                  <TableCell>{formatDate(task.created_at)}</TableCell>
                  <TableCell>{formatDuration(task.execution_time)}</TableCell>
                  <TableCell>{task.rows_processed || 'N/A'}</TableCell>
                  <TableCell>
                    {task.error_message && (
                      <Tooltip title={task.error_message}>
                        <IconButton size="small" color="error">
                          <AlertTriangle className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TaskHistory;