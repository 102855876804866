// hooks/useSubscriptionData.js
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

export const useSubscriptionData = (isSignedIn, organization, getToken) => {
  const [data, setData] = useState({
    tier: null,
    usage: null,
    isLoading: true,
    error: null
  });

  const fetchData = useCallback(async () => {
    if (!isSignedIn || !organization?.id) {
      setData(prev => ({ ...prev, isLoading: false }));
      return;
    }

    try {
      const token = await getToken();
      
      const response = await axios.get(`${BACKEND_URL}/api/usage`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-ID': organization.id,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });

      // Log the raw response
      console.log('Usage API Response:', response.data);

      setData({
        tier: response.data.tier,
        usage: response.data.usage,
        isLoading: false,
        error: null
      });

      // Log the state update
      console.log('Updated State:', {
        tier: response.data.tier,
        usage: response.data.usage
      });

    } catch (error) {
      console.error('Error fetching subscription data:', error);
      setData(prev => ({
        ...prev,
        isLoading: false,
        error: error.message
      }));
    }
  }, [isSignedIn, organization?.id]); // Removed getToken from dependencies

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, [fetchData]);

  return {
    ...data,
    refreshData: fetchData
  };
};