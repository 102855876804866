import React, { useMemo } from 'react';
import { 
  LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, ResponsiveContainer, Cell 
} from 'recharts';

const COLORS = [
  '#6366F1', '#10B981', '#F59E0B', '#EF4444', 
  '#8B5CF6', '#14B8A6', '#F97316', '#06B6D4'
];

const DataVisualization = ({ content }) => {
  const parsedData = useMemo(() => {
    try {
      // Handle different input types
      let data = typeof content === 'string' ? JSON.parse(content) : content;
      
      // Handle {type: 'table', data: [...]} format
      if (data?.type === 'table' && Array.isArray(data.data)) {
        return data.data;
      }
      
      // Handle direct array
      if (Array.isArray(data)) {
        return data;
      }

      return [];
    } catch (error) {
      console.error('Error parsing data:', error);
      return [];
    }
  }, [content]);

  const chartType = useMemo(() => {
    if (!parsedData.length) return null;
    
    const keys = Object.keys(parsedData[0]);
    const hasDate = keys.some(k => k.toLowerCase().includes('date'));
    const hasCategory = keys.some(k => k.toLowerCase().includes('category'));
    
    if (hasDate) return 'line';
    if (hasCategory) return 'bar';
    return 'bar'; // Default to bar chart
  }, [parsedData]);

  const renderChart = () => {
    if (!parsedData.length) return null;

    const keys = Object.keys(parsedData[0]);
    const numericKeys = keys.filter(key => 
      typeof parsedData[0][key] === 'number'
    );
    const categoryKey = keys.find(k => 
      k.toLowerCase().includes('category') || 
      k.toLowerCase().includes('date') || 
      typeof parsedData[0][k] === 'string'
    );

    switch (chartType) {
      case 'line':
        return (
          <ResponsiveContainer width="100%" height={250}>
            <LineChart data={parsedData} margin={{ top: 5, right: 20, left: 10, bottom: 25 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey={categoryKey}
                angle={-45}
                textAnchor="end"
                height={60}
                tick={{ fontSize: 12 }}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {numericKeys.map((key, index) => (
                <Line
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={COLORS[index % COLORS.length]}
                  strokeWidth={2}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        );

      case 'bar':
      default:
        return (
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={parsedData} margin={{ top: 5, right: 20, left: 10, bottom: 25 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey={categoryKey}
                angle={-45}
                textAnchor="end"
                height={60}
                tick={{ fontSize: 12 }}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {numericKeys.map((key, index) => (
                <Bar
                  key={key}
                  dataKey={key}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        );
    }
  };

  if (!parsedData.length) {
    return null;
  }

  return (
    <div style={{ height: 'auto', minHeight: 0 }}>
      <div style={{ marginBottom: '8px' }}>
        {renderChart()}
      </div>
      
      <div style={{ overflowX: 'auto', marginTop: '12px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.875rem' }}>
          <thead>
            <tr style={{ backgroundColor: '#F8FAFC' }}>
              {Object.keys(parsedData[0]).map(header => (
                <th 
                  key={header}
                  style={{ 
                    padding: '8px', 
                    textAlign: 'left', 
                    borderBottom: '1px solid #E2E8F0',
                    whiteSpace: 'nowrap' 
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {parsedData.map((row, idx) => (
              <tr key={idx} style={{ backgroundColor: idx % 2 === 0 ? 'white' : '#F8FAFC' }}>
                {Object.entries(row).map(([key, value], cellIdx) => (
                  <td 
                    key={`${idx}-${cellIdx}`}
                    style={{ 
                      padding: '8px 12px', 
                      borderBottom: '1px solid #E2E8F0',
                      whiteSpace: 'nowrap' 
                    }}
                  >
                    {typeof value === 'number' ? value.toLocaleString() : value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataVisualization;