import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Select, MenuItem, IconButton, List, ListItem, ListItemText, ListItemIcon, InputAdornment, Paper, Modal, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '../sendto.png';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { OrganizationSwitcher, useOrganization } from "@clerk/clerk-react";
import ChatIcon from '@mui/icons-material/Chat';
import StarIcon from '@mui/icons-material/Star';
import { CircularProgress, Alert } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import HypeLogo from '../HA_Logo.png';
import { useUser, useAuth } from "@clerk/clerk-react";
import rightArrow from '../Iconin.png';
import leftArrow from '../iconto.png';
import haclose from '../closeha.png';
import addChatImage from '../addchat.png';
import Divider from '@mui/material/Divider';
import './AnalyticsPage.css';
import AddPromptModal from './AddPromptModal';
import doc from '../doc.png';
import { useSubscriptionData } from '../hooks/useSubscriptionData';
import SubscriptionUsage from './SubscriptionUsage';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FileUploadSection } from './FileUpload';
import DataVisualization from './DataVisualization';
import MessageDisplay from './MessageDisplay';
import TypingIndicator from './TypingIndicator';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://ha-dataconnector-backend-vkuu.onrender.com';

const RightSidebar = styled(Box)(({ theme, open }) => ({
  width: open ? 250 : 60,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderLeft: '1px solid #E2E8F0',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8FAFC',
}));

const AppContainer = styled(Box)({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  });
  
  const Sidebar = styled(Box)(({ theme, open, side }) => ({
    width: open ? 250 : 60,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRight: side === 'left' ? '1px solid #E2E8F0' : 'none',
    borderLeft: side === 'right' ? '1px solid #E2E8F0' : 'none',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F8FAFC',
    position: 'fixed',
    top: 0,
    [side]: 0,
    zIndex: 1,
  }));
  
  
  const MainContent = styled(Box)(({ theme, leftOpen, rightOpen }) => ({
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create(['margin-left', 'margin-right'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: leftOpen ? 250 : 60,
    marginRight: rightOpen ? 250 : 60,
    display: 'flex',
    flexDirection: 'column',
  }));

  const ChatHeader = styled(Box)({
    position: 'sticky',
    top: 0,
    backgroundColor: '#F8FAFC',
    zIndex: 1,
    padding: '20px',
    borderBottom: '1px solid #E2E8F0',
  });
  
  const ChatContent = styled(Box)({
    flexGrow: 1,
    overflowY: 'auto',
    padding: '20px',
  });
  
  const QueryBox = styled(Box)({
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#F8FAFC',
    padding: '20px',
    borderTop: '1px solid #E2E8F0',
  });
  
const StyledButton = styled(Button)({
  borderRadius: 8,
  textTransform: 'none',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 600,
});

const StyledSelect = styled(Select)({
  backgroundColor: '#F1F5F9',
  borderRadius: 8,
  marginLeft: '16px',  // Add this
  marginRight: '16px',  
  width: 'calc(100% - 32px)', 
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
const ChatListItem = styled(ListItem)({
  borderRadius: 8,
  marginBottom: 4,
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});

const MessageBubble = styled(Paper)(({ isUser }) => ({
  padding: '10px 15px',
  borderRadius: 20,
  maxWidth: '70%',
  marginBottom: 10,
  backgroundColor: isUser ? '#E3F2FD' : '#F5F5F5',
  alignSelf: isUser ? '' : 'flex-start',
}));

const ToggleContainer = styled(Box)({
  display: 'flex',
  borderRadius: 20,
  overflow: 'hidden',
  border: '1px solid #E2E8F0',
  marginBottom: 16,
});

const ToggleButton = styled(Button)(({ active }) => ({
  flex: 1,
  padding: '8px 16px',
  backgroundColor: active ? '#6366F1' : 'transparent',
  color: active ? '#fff' : '#64748B',
  '&:hover': {
    backgroundColor: active ? '#4F46E5' : '#F1F5F9',
  },
}));

const PromptModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PromptModalContent = styled(Box)({
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
});

const PromptChip = styled(Chip)({
  margin: '4px',
  cursor: 'pointer',
});


const SubscriptionBar = styled(Box)({
  backgroundColor: '#F1F5F9',
  padding: '8px 16px',
  borderRadius: '8px',
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const UsageIndicator = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const SubscriptionInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '12px 16px',
  backgroundColor: '#F8FAFC',
  borderRadius: '8px',
  margin: '8px 0',
  border: '1px solid #E2E8F0'
}));

const UsageStats = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  flexWrap: 'wrap'
});

const StatItem = ({ label, value = 0, total = 0, format = 'number' }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '150px' }}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <ProgressBar 
        value={Number(value) || 0} 
        total={Number(total) || 1} 
        sx={{ flex: 1 }}
      />
      <Typography variant="caption" sx={{ minWidth: '70px' }}>
        {format === 'number' 
          ? `${value || 0}/${total || 0}` 
          : `${((value || 0)/1000).toFixed(1)}k/${((total || 0)/1000)}k`
        }
      </Typography>
    </Box>
  </Box>
);

const ProgressBar = styled(Box)(({ value = 0, total = 100 }) => ({
  width: '100px',
  height: '4px',
  backgroundColor: '#E2E8F0',
  borderRadius: '2px',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: `${Math.min((value / total) * 100, 100)}%`,
    backgroundColor: (value / total) > 0.9 ? '#EF4444' : '#6366F1',
    borderRadius: '2px',
    transition: 'width 0.3s ease'
  }
}));

const handleQueryError = (error) => {
  console.error('Query error:', error);
  return error.message || 'An error occurred while fetching data';
};



const WelcomeScreen = ({ onCreateNewChat, recentChats, onSelectChat }) => {
  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 100px)',
    padding: '40px',
    backgroundColor: '#F8FAFC'
  };

  const contentStyles = {
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
  };

  const noChatsMessageStyles = {
    textAlign: 'center',
    color: '#64748B',
    padding: '32px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
  };

  const handleCreateNewChat = () => {
    // Generate default name with timestamp
    const defaultName = `New Chat ${new Date().toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    })}`;
    
    // Create chat directly with the timestamp name
    onCreateNewChat(defaultName);
  };

  return (
    <Box sx={containerStyles}>
      <Box sx={contentStyles}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" sx={{ color: '#1E293B', mb: 2 }}>
            Welcome to Analytics Chat
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Start a new conversation or continue from where you left off
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={handleCreateNewChat}
            sx={{
              py: 2,
              px: 4,
              backgroundColor: '#6366F1',
              '&:hover': { backgroundColor: '#4F46E5' }
            }}
          >
            Create New Chat
          </Button>
        </Box>

        <Box sx={{ 
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
          overflow: 'hidden'
        }}>
          <Typography 
            variant="h6" 
            sx={{ 
              p: 3, 
              borderBottom: '1px solid #E2E8F0',
              color: '#1E293B'
            }}
          >
            Recent Conversations
          </Typography>

          {recentChats.length > 0 ? (
            <List sx={{ p: 2 }}>
              {recentChats.map((chat) => (
                <ListItem 
                  key={chat.id}
                  button
                  onClick={() => onSelectChat(chat)}
                  sx={{
                    borderRadius: '8px',
                    mb: 1,
                    '&:hover': { 
                      backgroundColor: '#F1F5F9',
                      transform: 'translateY(-1px)',
                      transition: 'all 0.2s ease'
                    }
                  }}
                >
                  <ListItemIcon>
                    <ChatIcon sx={{ color: '#6366F1' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography variant="subtitle1" sx={{ color: '#1E293B' }}>
                        {chat.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" sx={{ color: '#64748B' }}>
                        Last updated: {new Date(chat.updated_at).toLocaleString()}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Box sx={noChatsMessageStyles}>
              <Typography variant="body1">
                No recent chats. Create a new one to get started!
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const AnalyticsPage = () => {
  // First declare all auth/organization related hooks
  const { getToken } = useAuth();
  const { organization, isLoaded: orgLoaded } = useOrganization(); 
  const { isLoaded, isSignedIn, user } = useUser();

  // Then declare the subscription hook that depends on them
  const { 
    tier: subscriptionData, 
    usage: usageData, 
    isLoading: subscriptionLoading, 
    error: subscriptionError,
    refreshData: refreshSubscription 
  } = useSubscriptionData(isSignedIn, organization, getToken);

  // Then declare all other state hooks
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [isEditingChatName, setIsEditingChatName] = useState(false);
  const [activeTab, setActiveTab] = useState('analytics');
  const [userEmail, setUserEmail] = useState('');
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);
  const [customPrompts, setCustomPrompts] = useState([]);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [newPrompt, setNewPrompt] = useState('');
  const [conversations, setConversations] = useState([]);
  const [showAllChats, setShowAllChats] = useState(false);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [editedChatName, setEditedChatName] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [promptGroups, setPromptGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newPromptContent, setNewPromptContent] = useState('');
  const [editingPromptId, setEditingPromptId] = useState(null);
  const [newGroupName, setNewGroupName] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [currentChat, setCurrentChat] = useState(null);
  const [uploadedFileInfo, setUploadedFileInfo] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [maxTokens, setMaxTokens] = useState(1000); // Default value until server response
  const [currentChatName, setCurrentChatName] = useState('New Chat');

  

  // Constants
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  const api = axios.create({
    baseURL: BACKEND_URL
  });

  
  
  useEffect(() => {
    fetchClients();
    fetchRecentChats();
    fetchUserEmail();
    const email = localStorage.getItem('userEmail');
    setUserEmail(email);
  }, []);

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    if (organization && isSignedIn) {
      fetchClients();
    }
  }, [organization?.id, isSignedIn]);

  const fetchClients = async () => {
    if (!organization || !isSignedIn) return;
    
    try {
      setLoading(true);
      const token = await getToken();
      
      const response = await axios.get(`${BACKEND_URL}/api/clients`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      setClients(response.data);
      setError(null);
      if (selectedClient && !response.data.includes(selectedClient)) {
        setSelectedClient('');
      }
      
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients. Please try again later.');
      setClients([]);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (isLoaded && isSignedIn) {
      fetchClients();
    }
  }, [isLoaded, isSignedIn]);
  

  const handleClientChange = (event) => {
    const selectedValue = event.target.value;
    // If clients are objects, find the full client object
    const selectedClientObj = Array.isArray(clients) 
      ? clients.find(client => client.database_name === selectedValue)
      : null;
      
    setSelectedClient(selectedValue);
  
    // If you need the full client object elsewhere
    if (selectedClientObj) {
      // Do something with the full client object if needed
      console.log('Selected client object:', selectedClientObj);
    }
  };


  const fetchConversations = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/get_conversations`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      console.log('Fetched conversations response:', response);
      
      if (response.data) {
        // Ensure we're getting the expected data structure
        console.log('First conversation in list:', response.data[0]);
        setConversations(response.data);
        setRecentChats(response.data);
      }
      
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  useEffect(() => {
    // This will log whenever conversations are updated
    console.log('Conversations updated:', conversations);
  }, [conversations]);

  const saveConversation = async () => {
    if (!currentConversationId || chatHistory.length === 0) {
      console.log('No conversation to save');
      return;
    }
  
    try {
      const token = await getToken();
      
      const formattedHistory = chatHistory.map(msg => ({
        role: msg.role === 'user' ? 'user' : 'assistant',
        content: msg.content,
        timestamp: new Date().toISOString()
      }));
  
      const response = await axios.post(`${BACKEND_URL}/api/save_conversation`, {
        conversation_id: currentConversationId,
        chat_history: formattedHistory,
        current_name: currentChatName
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Organization-ID': organization?.id
        }
      });
  
      // Only update name if we get a new one and current is default
      if (response.data?.name && 
          response.data.name !== currentChatName && 
          currentChatName.startsWith('New Chat')) {
        console.log('Updating chat name to:', response.data.name);
        setCurrentChatName(response.data.name);
      }
  
    } catch (error) {
      console.error('Error saving conversation:', error);
    }
  };
  
  // Update the chat name update effect
  useEffect(() => {
    const updateName = async () => {
      // Only update if we have enough history and a valid conversation
      if (chatHistory.length >= 2 && currentConversationId && currentChatName) {
        await saveConversation();
      }
    };
    updateName();
  }, [chatHistory.length]);
  
  // Update current chat effect
  useEffect(() => {
    if (currentChat?.name) {
      setCurrentChatName(currentChat.name);
    } else if (currentChat) {
      // Provide a default name if none exists
      setCurrentChatName(`New Chat ${new Date().toLocaleString()}`);
    }
  }, [currentChat]);


  

  const fetchRecentChats = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/get_conversations`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setRecentChats(response.data);
    } catch (error) {
      console.error('Error fetching recent chats:', error);
    }
  };

  const fetchUserEmail = async () => {
    setUserEmail('user@example.com');
  };

  const formatResetDate = (dateString, days) => {
    if (!dateString) return 'Not available';
    
    const daysUntilReset = days || 0;
    
    if (daysUntilReset <= 0) {
      return 'Today';
    } else if (daysUntilReset === 1) {
      return 'Tomorrow';
    } else {
      return `in ${daysUntilReset} days`;
    }
};

  const getUsagePercentage = (used, total) => {
    return Math.min((used / total) * 100, 100);
  };

  const getUsageColor = (percentage) => {
    if (percentage >= 90) return '#EF4444';
    if (percentage >= 75) return '#F59E0B';
    return '#6366F1';
  };

  const handleQuerySubmit = async () => {
    if (!query.trim() || !selectedClient || limitReached) return;
  
    const currentQuery = query.trim();
    
    try {
      const token = await getToken();
      
      // Add user message to history
      const userMessage = { role: 'user', content: currentQuery };
      const updatedHistory = [...chatHistory, userMessage];
      setChatHistory(updatedHistory);
      setQuery('');
      setIsProcessing(true);
      
      const requestBody = {
        client: selectedClient,
        query: currentQuery,
        conversation_id: currentChat?.id || currentConversationId,
        file_id: uploadedFileInfo?.id
      };
  
      console.log('[CHAT NAME DEBUG] Sending query:', requestBody);
  
      const response = await axios.post(`${BACKEND_URL}/api/query`, requestBody, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Organization-ID': organization?.id
        }
      });
  
      // Add AI response to history
      const aiMessage = { role: 'assistant', content: response.data.result };
      const newHistory = [...updatedHistory, aiMessage];
      setChatHistory(newHistory);
  
      console.log('[CHAT NAME DEBUG] Updated chat history:', newHistory);
  
      // Save conversation with complete history
      const saveResponse = await axios.post(`${BACKEND_URL}/api/save_conversation`, {
        conversation_id: currentConversationId,
        chat_history: newHistory,
        current_name: currentChatName
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Organization-ID': organization?.id
        }
      });
  
      console.log('[CHAT NAME DEBUG] Save response:', saveResponse.data);
  
      if (saveResponse.data.name && 
          saveResponse.data.name !== currentChatName && 
          currentChatName.startsWith('New Chat')) {
        console.log('[CHAT NAME DEBUG] Updating chat name to:', saveResponse.data.name);
        setCurrentChatName(saveResponse.data.name);
      }
  
      if (response.data.is_near_limit) {
        setError(response.data.warning);
        setShowWarning(true);
        setLimitReached(false);
      }
  
      await fetchConversations();
  
    } catch (error) {
      console.error('[CHAT NAME DEBUG] Error:', error);
      if (error.response?.data?.limit_reached) {
        setLimitReached(true);
        setError("Token limit reached. Please start a new chat.");
        setShowWarning(true);
      }
    } finally {
      setIsProcessing(false);
    }
  };


const renderChatHeader = () => (
// In your ChatHeader section, update the styling:
<ChatHeader sx={{ 
  position: 'sticky',
  top: 0,
  backgroundColor: '#FFFFFF',
  zIndex: 1,
  borderBottom: '1px solid #E2E8F0',
  padding: '16px 24px' // Adjusted padding
}}>
  <Box>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      mb: 2,
      mt: 1 // Added top margin
    }}>
      {isEditingChatName ? (
        <>
          <TextField
            value={editedChatName}
            onChange={(e) => setEditedChatName(e.target.value)}
            autoFocus
            fullWidth
            variant="standard"
            sx={{ mr: 1 }}
          />
          <IconButton onClick={handleSaveChatName} size="small">
            <SaveIcon />
          </IconButton>
          <IconButton onClick={handleCancelEdit} size="small">
            <CancelIcon />
          </IconButton>
        </>
      ) : (
        <>
          <Typography 
            variant="h5" 
            sx={{ 
              fontWeight: 600,
              color: '#1E293B',
              flex: 1
            }}
          >
            {currentChatName}
          </Typography>
          <IconButton 
            onClick={handleEditChatName} 
            size="small"
            sx={{ color: '#64748B' }}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
    </Box>

    <SubscriptionUsage
      subscriptionData={subscriptionData}
      usageData={usageData}
      subscriptionLoading={subscriptionLoading}
      subscriptionError={subscriptionError}
      navigate={navigate}
    />
  </Box>
</ChatHeader>
);

useEffect(() => {
  if (currentChat) {
    setCurrentChatName(currentChat.name);
  }
}, [currentChat]);
const handleCreateNewChat = async () => {
  try {
    const token = await getToken();
    
    const defaultName = `New Chat ${new Date().toLocaleString()}`;
    
    const response = await axios.post(`${BACKEND_URL}/api/save_conversation`, {
      chat_history: [],
      name: defaultName
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Organization-ID': organization?.id
      }
    });

    const newChat = response.data;
    setCurrentChat(newChat);
    setCurrentChatName(newChat.name || defaultName);
    setCurrentConversationId(newChat.id);
    setChatHistory([]);
    setShowWelcome(false);

    await fetchConversations();
  } catch (error) {
    console.error('Error creating chat:', error);
    setError('Failed to create chat. Please try again.');
  }
};

const handleSaveChatName = async () => {
  try {
    const token = await getToken();
    
    await axios.post(`${BACKEND_URL}/api/save_conversation`, {
      conversation_id: currentConversationId,
      name: editedChatName,
      chat_history: chatHistory // Include chat history for context
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Organization-ID': organization?.id
      }
    });

    setCurrentChatName(editedChatName);
    setIsEditingChatName(false);
    await fetchConversations();

  } catch (error) {
    console.error('Error saving chat name:', error);
    setError('Failed to update chat name. Please try again.');
  }
};


const handleSendMessage = async (message) => {
  try {
    // Add user message to chat history
    const userMessage = { isUser: true, message };
    const newChatHistory = [...chatHistory, userMessage];
    setChatHistory(newChatHistory);

    // This will trigger the auto-save effect above
    // which will send the updated chat history to the backend
    // and potentially update the conversation name

    // ... rest of your message handling logic ...
  } catch (error) {
    console.error('Error sending message:', error);
  }
};
const saveNewChat = async (chat) => {
  try {
    const token = await getToken();
    await axios.post(`${BACKEND_URL}/api/save_conversation`, {
      conversation_name: chat.name,
      chat_history: []
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    fetchRecentChats(); // Refresh the list
  } catch (error) {
    console.error('Error saving new chat:', error);
  }
};

const handleSelectChat = async (chat) => {
  setCurrentChat(chat);
  setShowWelcome(false);
  
  // Fetch chat history
  try {
    const token = await getToken();
    const response = await axios.get(`${BACKEND_URL}/api/get_conversation/${chat.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    setChatHistory(response.data.chat_history);
    
    // Check token count and set warning if needed
    const tokenCount = response.data.token_count || 0;
    const WARNING_THRESHOLD = 9000;
    const shouldShowWarning = tokenCount >= WARNING_THRESHOLD;
    setShowWarning(shouldShowWarning);
    
    if (shouldShowWarning) {
      setError("This conversation is nearing its length limit. Consider starting a new chat soon.");
    }
    
  } catch (error) {
    console.error('Error fetching chat history:', error);
  }
};


const handleChatSelect = async (chat) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BACKEND_URL}/api/get_conversation/${chat.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    
    setCurrentChat(response.data);
    setCurrentChatName(response.data.name);
    setCurrentConversationId(response.data.id);
    setChatHistory(response.data.chat_history || []);
    setShowWelcome(false);
    
    // Update maxTokens from server response if provided
    if (response.data.max_tokens) {
      setMaxTokens(response.data.max_tokens);
    }
    
    // Set warning and limit states based on token count
    const tokenCount = response.data.token_count || 0;
    const WARNING_THRESHOLD = 18000;

    if (tokenCount >= maxTokens) {
      setLimitReached(true);
      setShowWarning(true);
      setError("Token limit reached. Please start a new chat.");
    } else if (tokenCount >= WARNING_THRESHOLD) {
      setLimitReached(false);
      setShowWarning(true);
      setError("This conversation is nearing its length limit. Consider starting a new chat soon.");
    } else {
      setLimitReached(false);
      setShowWarning(false);
      setError(null);
    }
    
  } catch (error) {
    console.error('Error selecting chat:', error);
    setError('Failed to load chat. Please try again.');
  }
};


  const handleEditChatName = () => {
    setEditedChatName(currentChatName);
    setIsEditingChatName(true);
  };
  
  const handleCancelEdit = () => {
    setIsEditingChatName(false);
    setEditedChatName('');
  };



  const handleChatNameChange = (event) => {
    setCurrentChatName(event.target.value);
  };

  const handleChatNameSubmit = () => {
    setIsEditingChatName(false);
    setRecentChats(recentChats.map(chat => 
      chat.name === currentChatName ? { ...chat, name: currentChatName } : chat
    ));
  };


  const ViewAllChats = ({ conversations, onSelectChat, onClose }) => (
    <Box sx={{ 
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      zIndex: 1200,
      p: 2
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={onClose} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">All Conversations</Typography>
      </Box>
      
      <List sx={{ overflow: 'auto', height: 'calc(100% - 60px)' }}>
        {conversations.map((conv) => (
          <ChatListItem
            key={conv.id}
            selected={conv.id === currentConversationId}
            onClick={() => {
              onSelectChat(conv);
              onClose();
            }}
          >
            <ListItemIcon>
              <ChatIcon fontSize="small" sx={{ color: '#6366F1' }} />
            </ListItemIcon>
            <ListItemText
              primary={conv.name}
              secondary={new Date(conv.updated_at).toLocaleString()}
              primaryTypographyProps={{ fontSize: '14px' }}
            />
          </ChatListItem>
        ))}
      </List>
    </Box>
  );

  const onSelectChat = (chat) => {
    setCurrentChat(chat);
    setCurrentChatName(chat.name || `Chat ${new Date().toLocaleString()}`);  // Fallback name if none exists
    setCurrentConversationId(chat.id);
    setShowWelcome(false);
    
    // If chat has history, set it
    if (chat.chat_history) {
      try {
        const history = Array.isArray(chat.chat_history) 
          ? chat.chat_history 
          : JSON.parse(chat.chat_history);
        setChatHistory(history);
      } catch (error) {
        console.error('Error parsing chat history:', error);
        setChatHistory([]);
      }
    } else {
      setChatHistory([]);
    }
  };
  

  const toggleLeftSidebar = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  };

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const handleSelectPrompt = (prompt) => {
    setQuery(prompt);
  };

  const handleCreateNewClient = () => {
    navigate('/datasource-connector');
  };


  useEffect(() => {
    fetchPrompts();
    fetchPromptGroups();
  }, []);

  const fetchPrompts = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/prompts`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setError('Failed to fetch prompts. Please try again later.');
      setPrompts([]);
    }
  };



  const fetchPromptGroups = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/prompt_groups`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPromptGroups(response.data);
      console.log('Fetched prompt groups:', response.data);
    } catch (error) {
      console.error('Error fetching prompt groups:', error);
      setError('Failed to fetch prompt groups. Please try again later.');
    }
  };
  

  const handleAddPrompt = async () => {
    if (!selectedGroup || !newPromptContent.trim()) return;
    
    try {
      const token = await getToken();
      const response = await axios.post(`${BACKEND_URL}/api/prompts`, {
        group_name: selectedGroup,
        content: newPromptContent.trim()
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(prevPrompts => [...prevPrompts, response.data]);
      setNewPromptContent('');
      setError(null);
      console.log('Added new prompt:', response.data); 
    } catch (error) {
      console.error('Error adding prompt:', error);
      setError('Failed to add prompt. Please try again.');
    }
  };



  const handleUpdatePrompt = async (promptId) => {
    const promptToUpdate = prompts.find(p => p.id === promptId);
    if (!promptToUpdate) return;

    try {
      const token = await getToken();
      const response = await axios.put(`${BACKEND_URL}/api/prompts/${promptId}`, {
        content: promptToUpdate.content
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(prompts.map(p => p.id === promptId ? response.data : p));
      setEditingPromptId(null);
    } catch (error) {
      console.error('Error updating prompt:', error);
    }
  };

  const handleDeletePrompt = async (promptId) => {
    try {
      const token = await getToken();
      await axios.delete(`${BACKEND_URL}/api/prompts/${promptId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(prompts.filter(p => p.id !== promptId));
    } catch (error) {
      console.error('Error deleting prompt:', error);
    }
  };

  const handleCreateNewGroup = async () => {
    if (!newGroupName.trim() || promptGroups.includes(newGroupName.trim())) return;
    
    try {
      const token = await getToken();
      await axios.post(`${BACKEND_URL}/api/prompt_groups`, {
        group_name: newGroupName.trim()
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPromptGroups(prevGroups => [...prevGroups, newGroupName.trim()]);
      setSelectedGroup(newGroupName.trim());
      setNewGroupName('');
      console.log('Created new group:', newGroupName.trim());
    } catch (error) {
      console.error('Error creating new group:', error);
      setError('Failed to create new group. Please try again.');
    }
  };

  const groupedPrompts = prompts.reduce((acc, prompt) => {
    if (!acc[prompt.group_name]) {
      acc[prompt.group_name] = [];
    }
    acc[prompt.group_name].push(prompt);
    return acc;
  }, {});



  const handleDeleteConversation = async (conversationId) => {
    try {
      const token = await getToken();
      
      await axios.delete(`${BACKEND_URL}/api/delete_conversation/${conversationId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      // Remove from local state
      setConversations(prev => prev.filter(conv => conv.id !== conversationId));
      
      // If the deleted conversation was the current one, go back to welcome screen
      if (currentConversationId === conversationId) {
        setShowWelcome(true);
        setCurrentChat(null);
        setCurrentConversationId(null);
        setChatHistory([]);
      }
  
      // Show success message
      setError('Chat deleted successfully');
      setTimeout(() => setError(null), 3000);
  
    } catch (error) {
      console.error('Error deleting conversation:', error);
      setError('Failed to delete conversation');
    }
  };


  useEffect(() => {
    if (selectedGroup && !groupedPrompts[selectedGroup]) {
      console.log('Selected group not found in groupedPrompts:', selectedGroup);
    }
  }, [groupedPrompts, selectedGroup]);
  
  const containsStructuredData = (content) => {
    try {
        // Try to parse as JSON first
        const parsed = JSON.parse(content);
        if (parsed && parsed.type === "table" && Array.isArray(parsed.data)) {
            return true;
        }
    } catch (e) {
        // If not JSON, check for table-like structures
        if (content.includes('|')) {
            const lines = content.split('\n').filter(line => line.trim());
            return lines.length > 1 && lines.some(line => /\d/.test(line));
        }
    }
    return false;
};


  const renderMainContent = () => {
    if (showWelcome) {
      return (
        <WelcomeScreen 
          onCreateNewChat={handleCreateNewChat} 
          recentChats={recentChats} 
          onSelectChat={handleSelectChat} 
        />
      );
    }
  
    const response = {}; // Define response variable
  
    return (
      <>
        {renderChatHeader()}
        {error && (
          <Box sx={{ padding: 2 }}>
            {error}
          </Box>
        )}
        <ChatContent className="chat-content">
          {chatHistory.map((message, index) => (
            <MessageDisplay key={index} message={message} />
          ))}
          {isProcessing && <TypingIndicator />}
        </ChatContent>
        {response?.is_near_limit && (
          <Alert 
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={handleCreateNewChat}
              >
                Start New Chat
              </Button>
            }
            sx={{
              position: 'sticky',
              bottom: '100px',
              zIndex: 1,
              mx: 2,
              boxShadow: 2,
              backgroundColor: '#FEF3C7',
              color: '#92400E'
            }}
          >
            This conversation is nearing its length limit. Consider starting a new chat soon.
          </Alert>
        )}
        {showWarning && (
          <Alert
            severity="warning"
            sx={{
              position: 'fixed',
              bottom: '80px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              zIndex: 1000,
              boxShadow: 3,
              backgroundColor: limitReached ? '#FEF2F2' : '#FEF3C7',
              color: limitReached ? '#991B1B' : '#92400E'
            }}
            action={
              <Button 
                color="inherit" 
                size="small" 
                onClick={handleCreateNewChat}
                sx={{
                  fontWeight: limitReached ? 600 : 400
                }}
              >
                Start New Chat
              </Button>
            }
          >
            {limitReached 
              ? "Token limit reached. Please start a new chat to continue."
              : "This conversation is nearing its length limit. Consider starting a new chat soon."
            }
          </Alert>
        )}
        <QueryBox className="query-box">
          <TextField
            className="query-input"
            value={query}
            onChange={(e) => !limitReached && setQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !limitReached && handleQuerySubmit()}
            placeholder={
              limitReached 
                ? "Token limit reached. Click 'Start New Chat' to continue."
                : "Reply to HYPE AWARENESS"
            }
            fullWidth
            variant="outlined"
            disabled={limitReached}
            sx={{ 
              backgroundColor: '#fff', 
              borderRadius: 2,
              opacity: limitReached ? 0.7 : 1,
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#666",
                cursor: "not-allowed"
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FileUploadUI 
                    onFileSelect={handleFileUpload}
                    isUploading={isUploading}
                    disabled={limitReached}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" className="input-end-adornment">
                  <IconButton 
                    onClick={handleQuerySubmit} 
                    className="send-button"
                    disabled={limitReached || isUploading}
                    sx={{
                      opacity: limitReached ? 0.5 : 1,
                      '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26)'
                      }
                    }}
                  >
                    <img 
                      src={SendIcon} 
                      alt="Send" 
                      className="send-icon" 
                      style={{ 
                        width: "24px", 
                        height: "24px",
                        filter: limitReached ? 'grayscale(100%)' : 'none'
                      }} 
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </QueryBox>
      </>
    );
  };

  const FileUploadUI = ({ onFileSelect, isUploading }) => {
    return (
      <label htmlFor="file-upload">
        <input
          type="file"
          id="file-upload"
          style={{ display: 'none' }}
          onChange={(e) => {
            if (e.target.files?.[0]) {
              onFileSelect(e.target.files[0]);
            }
          }}
          accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
        />
        <IconButton
          component="span"
          disabled={isUploading}
          sx={{
            color: '#6366F1',
            '&:hover': {
              backgroundColor: '#EEF2FF',
            },
            marginRight: '8px',
            position: 'relative'
          }}
        >
          {isUploading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#6366F1',
                position: 'absolute'
              }}
            />
          ) : (
            <AttachFileIcon />
          )}
        </IconButton>
      </label>
    );
  };

  const handleFileUpload = async (file) => {
    try {
      setIsUploading(true);
      
      const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
      if (file.size > MAX_FILE_SIZE) {
        setUploadError('File size exceeds 10MB limit');
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);
      formData.append('filetype', file.type);
      formData.append('conversationId', currentConversationId);
      
      const token = await getToken();
      const response = await axios.post(`${BACKEND_URL}/api/upload_file`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data.success) {
        // Add file message to chat history
        const fileMessage = {
          role: 'system',
          content: `<document>\n<source>${file.name}</source>\n</document>`,
          fileInfo: {
            id: response.data.file_id,
            name: file.name,
            type: file.type,
            size: (file.size / (1024 * 1024)).toFixed(2) + ' MB'
          }
        };
        
        setChatHistory(prev => [...prev, fileMessage]);
        
        // Automatically set query to analyze file
        if (file.type.includes('text') || 
            file.type.includes('pdf') || 
            file.type.includes('document') ||
            file.type.includes('sheet')) {
          setQuery(`Please analyze the content of ${file.name}.`);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError(error.response?.data?.error || 'Failed to upload file');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveFile = async () => {
    try {
      if (!uploadedFileInfo?.id) return;
      
      const token = await getToken();
      await axios.delete(`${BACKEND_URL}/api/remove_file/${uploadedFileInfo.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      // Add system message about file removal
      setChatHistory(prev => [...prev, {
        role: 'system',
        content: `File removed: ${uploadedFileInfo.name}`
      }]);
      
      setUploadedFileInfo(null);
      setUploadError(null);
    } catch (error) {
      console.error('Error removing file:', error);
      setUploadError('Failed to remove file');
    }
  };

  useEffect(() => {
    if (currentChat?.token_count >= maxTokens) {
      setLimitReached(true);
      setShowWarning(true);
      setError("Token limit reached. Please start a new chat.");
    }
  }, [currentChat, maxTokens]);

  return (
    
    <AppContainer>
      {/* Left Sidebar */}
      <Sidebar open={leftSidebarOpen} side="left" className="left-sidebar">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
          {leftSidebarOpen && (
            <Link to="/home" style={{ textDecoration: 'none' }}>
              <img src={HypeLogo} alt="Hype Awareness" style={{ width: 80, marginBottom: 20 }} />
            </Link>
          )}
          <IconButton 
            className="toggle-sidebar-button" 
            onClick={() => setLeftSidebarOpen(!leftSidebarOpen)}
          >
            {leftSidebarOpen ? (
              <img src={leftArrow} alt="Left Arrow" className="left-arrow-img" />
            ) : (
              <img src={rightArrow} alt="Right Arrow" className="right-arrow-img" />
            )}
          </IconButton>
        </Box>
        {leftSidebarOpen && (
          <>
      {/* User and Organization Section */}


      <Box sx={{ padding: '20px', borderBottom: '1px solid #E2E8F0' }}>
          <Typography variant="caption" sx={{ fontWeight: 700, display: 'block', mb: 1 }}>
            Organization
          </Typography>
          <OrganizationSwitcher 
            appearance={{
              elements: {
                rootBox: {
                  width: '100%',
                },
                organizationSwitcherTrigger: {
                  padding: '8px',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  backgroundColor: '#F8FAFC'
                }
              }
            }}
          />
        </Box>

     
      <ToggleContainer className="toggle-container">
        <ToggleButton
          className="toggle-button-a"
          active={activeTab === 'analytics'}
          onClick={() => setActiveTab('analytics')}
        >
          Analytics
        </ToggleButton>
        <ToggleButton
          className="toggle-button-l"
          active={activeTab === 'leads'}
          onClick={() => setActiveTab('leads')}
        >
          Leads
        </ToggleButton>
      </ToggleContainer>
      <Divider sx={{ borderColor: '#E2E8F0', borderWidth: '1px' }} />
      <StyledButton
        className="create-new-client-button"
        variant="contained"
        fullWidth
        sx={{
          margin: 2,
          backgroundColor: '#6366F1',
          '&:hover': { backgroundColor: '#4F46E5' },
        }}
        onClick={handleCreateNewClient}
      >
        Create New Client
      </StyledButton>
            <p className='Select Client'>Select Client</p>      
            <StyledSelect
              value={selectedClient}
              onChange={handleClientChange}
              fullWidth
              displayEmpty
              sx={{ 
                margin: 2,
                backgroundColor: '#F1F5F9',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              <MenuItem value="" disabled>
                {loading ? 'Loading clients...' : 'Select Client'}
              </MenuItem>
              {Array.isArray(clients) && clients.map((client) => (
                <MenuItem 
                  key={typeof client === 'string' ? client : client.database_name} 
                  value={typeof client === 'string' ? client : client.database_name}
                >
                  {typeof client === 'string' ? client : client.database_name}
                </MenuItem>
              ))}
            </StyledSelect>
            <Divider sx={{ borderColor: '#E2E8F0', borderWidth: '1px' }} />
            <Box sx={{ padding: '20px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>Recent Chats</Typography>
                {conversations.length > 5 && (
                  <Button
                    size="small"
                    onClick={() => setShowAllChats(true)}
                    sx={{ color: '#6366F1' }}
                  >
                    View All
                  </Button>
                )}
              </Box>

              <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {conversations.slice(0, 5).map((conv) => (
                  <ChatListItem
                    key={conv.id}
                    className="chat-list-item" 
                    selected={conv.id === currentConversationId}
                    onClick={() => handleChatSelect(conv)}
                  >
                    <ListItemIcon className="list-item-icon">  
                      <ChatIcon fontSize="small" sx={{ color: '#6366F1' }} /> 
                    </ListItemIcon>
                    <ListItemText
                      primary={conv.name}
                      secondary={new Date(conv.updated_at).toLocaleString()}
                      className="list-item-text" 
                      primaryTypographyProps={{ 
                        fontSize: '14px',
                        fontWeight: conv.id === currentConversationId ? 600 : 400 
                      }}
                    />
                  </ChatListItem>
                ))}
              </List>
            </Box>
            <StyledButton
              className="start-new-chat-button"
              startIcon={<ChatIcon />}
              variant="contained"
              fullWidth
              sx={{
                margin: 2,
                backgroundColor: '#6366F1',
                '&:hover': { backgroundColor: '#4F46E5' },
              }}
              onClick={handleCreateNewChat}
            >
              Start New Chat
          </StyledButton>





            <Box sx={{ mt: 2, borderTop: '1px solid #E2E8F0', pt: 2 }}>
            <Link to="/home" style={{ textDecoration: 'none' }}>
              <StyledButton
                startIcon={<HomeIcon size={20} />}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  color: '#6366F1',
                  borderColor: '#6366F1',
                  '&:hover': {
                    borderColor: '#4F46E5',
                    backgroundColor: 'rgba(99, 102, 241, 0.04)'
                  }
                }}
              >
                Back to Home
              </StyledButton>
            </Link>
          </Box>

          <Box sx={{ mt: 'auto', p: 2, borderTop: '1px solid #E2E8F0' }}>
                <Typography variant="body2">{userEmail}</Typography>
            </Box>

          </>
          


        )}
      </Sidebar>
      
      <MainContent leftOpen={leftSidebarOpen} rightOpen={rightSidebarOpen}>
        {renderMainContent()}
      </MainContent>

      <Sidebar open={rightSidebarOpen} side="right">
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
    {rightSidebarOpen && <Typography variant="h6" className="prompt-library-title">Prompt Library</Typography>}
    <IconButton onClick={() => setRightSidebarOpen(!rightSidebarOpen)}>
      {rightSidebarOpen ? <img src={haclose} alt="Right Arrow" /> : <img src={leftArrow} alt="Left Arrow" />}
    </IconButton>
  </Box>
  {rightSidebarOpen && (
    <Box sx={{ padding: 2 }}>
      {error && (
        <Typography color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}
      <p className="Select Client">Select Category</p>

      <Select
        value={selectedGroup}
        onChange={(e) => {
          console.log('Selected group changed to:', e.target.value);
          setSelectedGroup(e.target.value);
        }}
        fullWidth
        displayEmpty
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value="" disabled>Select Category</MenuItem>
        {[...promptGroups].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((group) => (
          <MenuItem key={group} value={group}>{group}</MenuItem>
        ))}
      </Select>

      <Box sx={{ display: 'flex', marginBottom: 2 }}>
        <TextField
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          placeholder="New group name"
          size="small"
          sx={{ flexGrow: 1, marginRight: 1 }}
        />
        <Button
          variant="contained"
          onClick={handleCreateNewGroup}
          disabled={!newGroupName.trim() || promptGroups.includes(newGroupName.trim())}
        >
          Create
        </Button>
      </Box>
      
      <AddPromptModal
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        newPromptContent={newPromptContent}
        setNewPromptContent={setNewPromptContent}
        handleAddPrompt={handleAddPrompt}
      />

      {Object.entries(groupedPrompts)
        .filter(([group]) => group === selectedGroup) 
        .map(([group, groupPrompts]) => (
          <Box key={group} sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{group}</Typography>
            <List>
              {[...groupPrompts]
                  .sort((a, b) => a.content.toLowerCase().localeCompare(b.content.toLowerCase()))
                  .map((prompt) => (
                    <ListItem key={prompt.id} sx={{ padding: '4px 0' }}>
                      {editingPromptId === prompt.id ? (
                    <>
                      <TextField
                        value={prompt.content}
                        onChange={(e) => setPrompts(prevPrompts => 
                          prevPrompts.map(p => p.id === prompt.id ? {...p, content: e.target.value} : p)
                        )}
                        fullWidth
                        multiline
                        size="small"
                      />
                      <IconButton onClick={() => handleUpdatePrompt(prompt.id)} size="small">
                        <SaveIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => setEditingPromptId(null)} size="small">
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Tooltip 
                        title={prompt.content}
                        placement="left"
                        arrow
                        enterDelay={500}
                        sx={{
                          maxWidth: 300, // Maximum width of the tooltip
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: '#1E293B',
                            color: '#fff',
                            fontSize: '0.875rem',
                            padding: '8px 12px',
                            borderRadius: '6px',
                            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                          }
                        }}
                      >
                        <ListItemText 
                          primary={prompt.content}
                          primaryTypographyProps={{ 
                            noWrap: true,
                            sx: { 
                              cursor: 'pointer',
                              '&:hover': { color: '#6366F1' }
                            }
                          }}
                          onClick={() => handleSelectPrompt(prompt.content)}
                        />
                      </Tooltip>
                      <IconButton onClick={() => setEditingPromptId(prompt.id)} size="small">
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleDeletePrompt(prompt.id)} size="small">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
      ))}
      <Button
          variant="contained"
          fullWidth
          className="add-prompt-button"
          startIcon={<img src={doc} alt="doc" />}
          onClick={() => setIsPopupOpen(true)}
          sx={{ marginBottom: 2 }}
        >
          Add Your Own Prompt
      </Button>
    </Box>
  )}


</Sidebar>


<Modal
  open={showAllChats}
  onClose={() => setShowAllChats(false)}
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}
>
  <Box sx={{
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '90%',
    maxWidth: 600,
    maxHeight: '80vh',
    borderRadius: 2,
    overflow: 'auto'
  }}>
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 2 
    }}>
      <Typography variant="h6">All Conversations</Typography>
      <IconButton onClick={() => setShowAllChats(false)}>
        <CloseIcon />
      </IconButton>
    </Box>
    
    <List>
      {conversations.map((conv) => (
        <ListItem
          key={conv.id}
          sx={{
            borderRadius: 1,
            mb: 1,
            '&:hover': { backgroundColor: '#F1F5F9' },
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box 
            onClick={() => {
              handleChatSelect(conv);
              setShowAllChats(false);
            }}
            sx={{ 
              flex: 1, 
              display: 'flex', 
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <ListItemIcon>
              <ChatIcon sx={{ color: '#6366F1' }} />
            </ListItemIcon>
            <ListItemText
              primary={conv.name}
              secondary={new Date(conv.updated_at).toLocaleString()}
            />
          </Box>
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm('Are you sure you want to delete this conversation?')) {
                handleDeleteConversation(conv.id);
              }
            }}
            sx={{ 
              color: 'error.main',
              '&:hover': { backgroundColor: 'error.lighter' }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
    </List>
    
    {conversations.length === 0 && (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography color="text.secondary">
          No conversations yet. Start a new chat to begin!
        </Typography>
      </Box>
    )}
  </Box>
</Modal>


    </AppContainer>
  );
};


export default AnalyticsPage;