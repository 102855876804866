import React from 'react';
import { Box } from '@mui/material';

const TypingIndicator = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        mb: 2
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '12px',
          padding: '12px 16px',
          boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
          border: '1px solid #E2E8F0',
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}
      >
        <Box
          className="typing-dot"
          sx={{
            width: '8px',
            height: '8px',
            backgroundColor: '#6366F1',
            borderRadius: '50%',
            animation: 'typingAnimation 1.4s infinite',
            '&:nth-of-type(2)': {
              animationDelay: '0.2s'
            },
            '&:nth-of-type(3)': {
              animationDelay: '0.4s'
            },
            '@keyframes typingAnimation': {
              '0%, 100%': {
                transform: 'translateY(0)',
                opacity: 0.4
              },
              '50%': {
                transform: 'translateY(-4px)',
                opacity: 1
              }
            }
          }}
        />
        <Box className="typing-dot" sx={{
          width: '8px',
          height: '8px',
          backgroundColor: '#6366F1',
          borderRadius: '50%',
          animation: 'typingAnimation 1.4s infinite',
          animationDelay: '0.2s'
        }} />
        <Box className="typing-dot" sx={{
          width: '8px',
          height: '8px',
          backgroundColor: '#6366F1',
          borderRadius: '50%',
          animation: 'typingAnimation 1.4s infinite',
          animationDelay: '0.4s'
        }} />
      </Box>
    </Box>
  );
};

export default TypingIndicator;