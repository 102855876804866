// SubscriptionUsage.jsx
import React, { memo } from 'react';
import { Box, Typography, Button, Chip, CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';

const StatItem = memo(({ label, value, total, format = 'number' }) => {
  const percentage = Math.min((value / total) * 100, 100);
  
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 2,
      minWidth: '200px'
    }}>
      <Typography variant="body2" color="text.secondary" sx={{ minWidth: '100px' }}>
        {label}
      </Typography>
      <Box sx={{ 
        flex: 1,
        height: 4,
        bgcolor: '#E2E8F0',
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden'
      }}>
        <Box sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: `${percentage}%`,
          bgcolor: percentage >= 90 ? '#EF4444' : percentage >= 70 ? '#F59E0B' : '#6366F1',
          borderRadius: 2,
          transition: 'width 0.3s ease'
        }} />
      </Box>
      <Typography variant="caption" sx={{ minWidth: '70px', textAlign: 'right' }}>
        {format === 'tokens' 
          ? `${(value/1000).toFixed(1)}k/${(total/1000)}k`
          : `${value}/${total}`}
      </Typography>
    </Box>
  );
});

const SubscriptionUsage = memo(({
  subscriptionData,
  usageData,
  subscriptionLoading,
  subscriptionError,
  navigate
}) => {
  // Add debug logging
  console.log('SubscriptionUsage render:', {
    data: {
      used: usageData?.messages?.used,
      limit: usageData?.messages?.limit,
      available: usageData?.messages?.available
    },
    subscription: subscriptionData
  });
  if (subscriptionLoading) {
    return <CircularProgress size={20} sx={{ m: 1 }} />;
  }

  if (subscriptionError) {
    return (
      <Alert severity="error" sx={{ py: 0.5 }}>
        Unable to load subscription data
      </Alert>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      p: 1.5,
      bgcolor: '#F8FAFC',
      borderRadius: 1,
      border: '1px solid #E2E8F0'
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Chip
            label={subscriptionData?.name || 'FREE'}
            size="small"
            sx={{
              bgcolor: subscriptionData?.name === 'FREE' ? '#94A3B8' : 
                      subscriptionData?.name === 'GROWTH' ? '#6366F1' : '#4F46E5',
              color: 'white',
              height: 20,
              '& .MuiChip-label': {
                px: 1,
                fontSize: '0.75rem',
                fontWeight: 600
              }
            }}
          />
          <Typography variant="caption" color="text.secondary">
            Resets in {usageData?.days_until_reset || 0} days
          </Typography>
        </Box>
        {(!subscriptionData || ['FREE', 'GROWTH'].includes(subscriptionData?.name)) && (
          <Button
            variant="text"
            size="small"
            onClick={() => navigate('/manage-subscription')}
            sx={{
              fontSize: '0.75rem',
              color: '#6366F1',
              p: 0,
              minWidth: 0,
              '&:hover': { bgcolor: 'transparent', textDecoration: 'underline' }
            }}
          >
            Upgrade
          </Button>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.75 }}>
        {usageData && (
          <>
            <StatItem
              label="Messages"
              value={usageData?.messages?.used || 0}
              total={usageData?.messages?.limit || 30}
            />
            <StatItem
              label="Input Tokens"
              value={usageData.input_tokens.used}
              total={subscriptionData?.max_monthly_input_tokens || 120000}
              format="tokens"
            />
            <StatItem
              label="Output Tokens"
              value={usageData.output_tokens.used}
              total={subscriptionData?.max_monthly_output_tokens || 60000}
              format="tokens"
            />
          </>
        )}
      </Box>
    </Box>
  );
});

export default SubscriptionUsage;