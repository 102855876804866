import React from 'react';
import { Box, Typography } from '@mui/material';
import DataVisualization from './DataVisualization';
import TypingIndicator from './TypingIndicator';

const MessageDisplay = ({ message }) => {
  const parseStructuredData = (content) => {
    try {
      if (!content || typeof content !== 'string') return null;

      // Split content into sections based on markdown headers
      const sections = content.split(/^##\s+/m).filter(Boolean);
      
      // Find the Data Visualization section
      const visualizationSection = sections.find(section => 
        section.trim().toLowerCase().startsWith('data visualization')
      );

      if (visualizationSection) {
        // Try to find and parse any JSON array in the content
        const jsonArrayMatch = visualizationSection.match(/\[\s*\{[^]*?\}\s*\]/);
        if (jsonArrayMatch) {
          const jsonArray = jsonArrayMatch[0];
          const data = JSON.parse(jsonArray);
          return {
            type: 'table',
            data: data
          };
        }
        
        // If no JSON array found, try to find a complete JSON object
        const jsonObjectMatch = visualizationSection.match(/\{[^]*?\}/);
        if (jsonObjectMatch) {
          return JSON.parse(jsonObjectMatch[0]);
        }
      }

      return null;
    } catch (error) {
      console.error('Error parsing visualization data:', error);
      return null;
    }
  };

  const formatText = (text) => {
    const processEmphasis = (content) => {
      // Handle triple asterisks first
      content = content.replace(/\*\*\*(.*?)\*\*\*/g, '<span class="heavy-emphasis">$1</span>');
      // Then double asterisks
      content = content.replace(/\*\*(.*?)\*\*/g, '<span class="emphasis">$1</span>');
      // Finally single asterisks
      content = content.replace(/\*(.*?)\*/g, '<span class="light-emphasis">$1</span>');
      return content;
    };

    const lines = text.split('\n').map((line, index) => {
      let formattedLine = line;

      // Update Typography components with reduced margins
      if (formattedLine.startsWith('###')) {
        const content = processEmphasis(formattedLine.replace(/^###\s*/, ''));
        return (
          <Typography
            key={index}
            variant="h6"
            component="div"
            sx={{
              mt: index === 0 ? 0 : 1,
              mb: 1,
              color: '#1E293B',
              fontWeight: 600,
              fontSize: '1.1rem',
              borderBottom: '1px solid #E2E8F0',
              paddingBottom: '0.25rem',
              textAlign: 'left',
              '& .emphasis, & .heavy-emphasis': {
                fontWeight: 700,
                color: '#1E293B'
              }
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        );
      }

      // Update all other Typography margins to 0.5rem (8px)
      return (
        <Typography
          key={index}
          variant="body1"
          component="div"
          sx={{
            mb: 0.5,
            color: '#475569',
            fontSize: '0.95rem',
            lineHeight: 1.5,
            textAlign: 'left'
          }}
          dangerouslySetInnerHTML={{ __html: processEmphasis(formattedLine) }}
        />
      );
    });

    return (
      <Box sx={{ 
        '& > *:first-child': { mt: 0 },
        '& > *:last-child': { mb: 0 },
        textAlign: 'left',
        width: '100%',
        '& > *': { mb: 0.5 }  // Add uniform spacing
      }}>
        {lines}
      </Box>
    );
  };

  const renderContent = () => {
    if (message.role === 'assistant') {
      const structuredData = parseStructuredData(message.content);
      const sections = message.content.split(/^##\s+/m);
      
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {sections[0] && formatText(sections[0])}
          
          {sections.slice(1).map((section, index) => {
            const sectionLines = section.split('\n');
            const sectionTitle = sectionLines[0].trim();
            const sectionContent = sectionLines.slice(1).join('\n');

            if (sectionTitle.toLowerCase() === 'data visualization') {
              return structuredData ? (
                <Box key={index} sx={{ mb: 2 }}>  {/* Add margin bottom */}
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#1E293B',
                      fontWeight: 600,
                      mb: 1.5,  // Add some space below title
                      textAlign: 'center',  // Center align the title
                      borderBottom: '1px solid #E2E8F0',
                      paddingBottom: '0.5rem'
                    }}
                  >
                    {sectionTitle}
                  </Typography>
                  <DataVisualization content={structuredData} />
                </Box>
              ) : null;
            }

            return (
              <Box key={index} sx={{ mb: 2 }}>  {/* Add consistent margin bottom */}
                <Typography
                  variant="h6"
                  sx={{
                    color: '#1E293B',
                    fontWeight: 600,
                    mb: 1.5,  // Consistent spacing
                    textAlign: 'center',  // Center align all section titles
                    borderBottom: '1px solid #E2E8F0',
                    paddingBottom: '0.5rem'
                  }}
                >
                  {sectionTitle}
                </Typography>
                {formatText(sectionContent)}
              </Box>
            );
          })}
        </Box>
      );
    }
    
    return (
      <Typography
        variant="body1"
        sx={{
          color: '#1E293B',
          fontSize: '0.95rem',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          textAlign: 'left'
        }}
      >
        {message.content}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
        mb: 1,  // Reduced from 2
        width: '100%',
        height: 'auto !important',
        opacity: 0,
        animation: 'fadeIn 0.3s ease-out forwards',
        '@keyframes fadeIn': {
          from: {
            opacity: 0,
            transform: 'translateY(10px)'
          },
          to: {
            opacity: 1,
            transform: 'translateY(0)'
          }
        }
      }}
    >
      {message.isLoading ? (
        <Box sx={{ /* ... keep existing styles ... */ }}>
          <TypingIndicator />
        </Box>
      ) : (
        <Box
          sx={{
            width: 'auto',
            height: 'auto',
            maxWidth: message.role === 'user' ? '75%' : '800px',
            minWidth: message.role === 'user' ? 'auto' : '600px',
            backgroundColor: message.role === 'user' ? '#E3F2FD' : '#FFFFFF',
            borderRadius: '12px',
            padding: '8px 12px',  // Reduced padding
            boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
            border: '1px solid',
            borderColor: message.role === 'user' ? '#BFDBFE' : '#E2E8F0'
          }}
        >
          {renderContent()}
        </Box>
      )}
    </Box>
  );
};

export default MessageDisplay;